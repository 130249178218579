<template>
  <div>
    <div v-if="lang == 'ka'">
      <vue-headful
        :title="[removeHTMLForTitle(data.titleKA)]"
        :description="[removeHTMLForTitle(data.descriptionKA)]"
        :keywords="data.keywords.toString()"
        image=""
        lang=""
        ogLocale=""
        url=""
      />
    </div>
    <div v-else>
      <vue-headful
        :title="[removeHTMLForTitle(data.titleEN)]"
        :description="[removeHTMLForTitle(data.descriptionEN)]"
        :keywords="data.keywords.toString()"
        image=""
        lang=""
        ogLocale=""
        url=""
      />
    </div>
    <Navbar />

    <div class="medContainer mb-5">
      <PathPage class="pt-3 pb-2" />
      <div class="">
        <p
          v-if="lang == 'ka'"
          v-html="data.subTitleKA"
          class="font-weight-bold blogMainTitle"
          style="font-family: mtavruliBOLD; color: #7f5496"
          role="paragraph"
        ></p>
        <p
          v-else
          v-html="data.subTitleEN"
          class="font-weight-bold blogMainTitle"
          style="font-family: mtavruliBOLD; color: #7f5496"
          role="paragraph"
        ></p>

        <h4
          v-if="lang == 'ka'"
          v-html="data.titleKA"
          style="font-family: mtavruliBOLD; color: #666; letter-spacing: 2px"
          role="header"
          class="mt-4 blogTitle"
        ></h4>
        <h4
          v-else
          v-html="data.titleEN"
          style="font-family: mtavruliBOLD; color: #666; letter-spacing: 2px"
          role="header"
          class="mt-4"
        ></h4>

        <div class="mt-3">
          <a style="font-family: mtavruli; color: #7f5496" role="date">
            <p v-if="lang == 'ka'" v-html="data.dateKA">{{ data.dateKA }}</p>
            <p v-else v-html="data.dateEN">{{ data.dateEN }}</p>
          </a>

          <b
            v-if="lang == 'ka'"
            class="authorTitle"
            v-html="data.authorKA"
            style="font-family: mtavruliBOLD"
            >{{ data.authorKA }}</b
          >
          <b
            class="authorTitle"
            v-else
            v-html="data.authorEN"
            style="font-family: mtavruliBOLD"
            >{{ data.authorEN }}</b
          >

          <div
            v-if="data.image"
            v-bind:style="{
              backgroundImage: 'url(' + server_url + '/' + data.image + ')',
            }"
            style="
              height: 500px;
              background-size: cover;
              background-repeat: no-repeat;
              background-position: center;
            "
            class="w-full postPhoto"
            role="image"
          ></div>
        </div>
        <div class="mt-4">
          <span v-if="lang == 'ka'" v-html="data.descriptionKA"> </span>
          <span v-else v-html="data.descriptionEN"> </span>
        </div>
        <div class="mt-5" v-if="data.video && data.video != 'null'">
          <iframe
            width="100%"
            height="415"
            :src="data.video"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      </div>
      <div class="pb-5 pt-5">
        <div
          class="
            d-flex
            pb-1
            pt-1
            justify-content-center
            sharingSide
            align-items-center
            mtavruli
            blackColor
          "
        >
          <span class="pr-3 mtavruli" style="text-transform: uppercase">{{
            $t("front.share")
          }}</span>
          <div v-if="lang == 'ka'" style="display: flex">
            <ShareNetwork
              v-for="network in networks"
              :network="network.network"
              :key="network.network"
              :url="shareNetwork"
              :title="data.titleKA"
              :quote="
                joinStrings([
                  removeHTML(data.titleKA),
                  removeHTML(data.descriptionKA),
                ])
              "
            >
              <div>
                <img
                  style="width: 31px"
                  class="p-1 d-flex"
                  :src="network.icon"
                  :alt="removeHTML(data.titleKA)"
                />
              </div>
            </ShareNetwork>
          </div>

          <div v-else style="display: flex">
            <ShareNetwork
              v-for="network in networks"
              :network="network.network"
              :key="network.network"
              :url="shareNetwork"
              :title="data.titleEN"
              :quote="
                joinStrings([
                  removeHTML(data.titleEN),
                  removeHTML(data.descriptionEN),
                ])
              "
            >
              <div>
                <img
                  style="width: 31px"
                  class="p-1"
                  :src="network.icon"
                  :alt="removeHTML(data.titleEN)"
                />
              </div>
            </ShareNetwork>
          </div>
        </div>
      </div>

      <div>
        <p style="text-transform: uppercase; color: #4d4d4c">
          <span class="dejavuBOLD"> {{ $t("front.disclaimer") }} </span><br />

          {{ $t("front.disclaimerText") }}
        </p>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
import image from "../../../../assets/icons/facebook7.png";
import imagelink from "../../../../assets/icons/in7.png";
import Navbar from "../../../../components/Navbar/newNavbar/Navbar.vue";
import Footer from "../../../../components/Footer/newFooter/Footer.vue";
import axios from "axios";
import PathPage from "./../../PathPage.vue";
import env from "../../../../env.json";

export default {
  data() {
    return {
      data: {},
      shareNetwork: "",
      server_url: env.server_url,
      social_network_share: env.social_network_share,
      lang: "ka",
      sharing: {
        url: "https://smart-pharm-project.netlify.app/blog/:id",
        title:
          "Say hi to Vite! A brand new, extremely fast development setup for Vue.",
        description:
          'This week, I’d like to introduce you to "Vite", which means "Fast". It’s a brand new development setup created by Evan You.',
        quote: "The hot reload is so fast it's near instant. - Evan You",
        hashtags: "vuejs,vite,javascript",
        twitterUser: "youyuxi",
      },
      networks: [
        {
          network: "facebook",
          name: "Facebook",
          icon: image,
          color: "#1877f2",
        },
        {
          network: "linkedin",
          name: "LinkedIn",
          icon: imagelink,
          color: "#007bb5",
        },
      ],
      author_show: false,
    };
  },
  methods: {
    removeHTMLForTitle: function (string) {
      return string.replace(/<\/?[^>]+>/gi, "");
    },
    removeHTML: function (string) {
      return string.replace(/<\/?[^>]+>/gi, " ");
    },
    joinStrings: function (string) {
      return string.join(" - ");
    },
  },
  mounted() {
    axios
      .get(`${env.host}/get/once/blog/${this.$route.params.slug}/null/null`)
      .then((result) => {
        this.shareNetwork = `${env.social_network_share}/blog/${this.$route.params.id}`;
        console.log(this.shareNetwork);
        this.data = result.data.item;
      })
      .catch((err) => console.log(err));
    if (localStorage.getItem("lang")) {
      this.lang = localStorage.getItem("lang");
    }
  },
  metaInfo(title) {
    return {
      title: "pagination",
      meta: [
        {
          name: "og:title",
          content: title,
        },
        {
          name: "og:image",
          content:
            "https://images.theconversation.com/files/256057/original/file-20190129-108364-17hlc1x.jpg?ixlib=rb-1.1.0&q=45&auto=format&w=1200&h=900.0&fit=crop",
        },
      ],
    };
  },

  name: "BlogDetailPage",
  components: {
    Navbar,
    Footer,
    PathPage,
  },
};
</script>

<style scoped>
.blogTitle >>> * {
  font-family: mtavruliBOLD;
  color: #666;
  letter-spacing: 2px;
}
::v-deep li::marker {
  unicode-bidi: normal !important;
  margin-left: 10px !important;
}

.li {
  padding-left: 20px;
  padding-right: 20px;
}
.blogMainTitle >>> * {
  font-family: mtavruliBOLD;
}
.authorTitle >>> * {
  font-family: mtavruliBOLD;
  color: #7e5493;
}
.medContainer {
  width: 100%;
  max-width: 1400px;
  margin: auto;
  min-height: calc(100vh - 258px);
}

.sharingSide {
  border-bottom: 1px solid #b3b3b3;
  border-top: 1px solid #b3b3b3;
}

@media all and (max-width: 1500px) {
  .medContainer {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media all and (max-width: 576px) {
  .postPhoto {
    height: 18rem !important;
  }
}
</style>
